import { Divider, Icon, Spinner } from '@ui-kitten/components';
import { AppStackScreenProps } from '../../navigation/utils/types';
import LoadingButton from '../../components/LoadingButton';
import ScreenWrapper from '../../components/ScreenWrapper';
import useUserProfile from '../../hooks/useUserProfile';
import React from 'react';
import UserProfile from '../../components/UserProfile';
import Post from '../../components/Post';
import useFriendRequests from '../../hooks/useFriendRequests';
import { View } from 'react-native';
import useFriends from '../../hooks/useFriends';
import useBlocks from '../../hooks/useBlocks';

export default function UserProfileScreen({
  navigation,
  route,
}: AppStackScreenProps<'UserProfile'>) {
  const profileId = route.params.profileId;
  const { userProfile, profileLoading, posts } = useUserProfile(profileId);
  const {
    createFriendRequest,
    sentFriendRequests,
    receivedFriendRequests,
    acceptFriendRequest,
    friendRequestsLoading,
  } = useFriendRequests();
  const { blockedUsers, blockProfile, unblockProfile, blockedUsersLoading } =
    useBlocks();
  const { friends, deleteFriend, getFriends, friendsLoading } = useFriends();

  const profileBlocked = blockedUsers?.find(
    ({ blocked_profile_id }) => blocked_profile_id === profileId,
  )
    ? true
    : false;

  const friendButtonText = () => {
    if (
      friends?.find(
        (friend) =>
          friend.from_profiles_id === profileId ||
          friend.to_profiles_id === profileId,
      )
    ) {
      return 'Remove friend';
    } else if (
      receivedFriendRequests?.find(
        (request) => request.from_profiles_id === profileId,
      )
    ) {
      return 'Accept friend request';
    } else if (
      sentFriendRequests?.find(
        (request) => request.to_profiles_id === profileId,
      )
    ) {
      return 'Request sent';
    } else {
      return 'Add friend';
    }
  };

  const blockButtonText = () => {
    if (profileBlocked) {
      return 'Unblock';
    } else {
      return 'Block';
    }
  };

  const friendButtonOnPress = async () => {
    if (
      friends?.find(
        (friend) =>
          friend.from_profiles_id === profileId ||
          friend.to_profiles_id === profileId,
      )
    ) {
      void deleteFriend(profileId);
    } else if (
      receivedFriendRequests?.find(
        (request) => request.from_profiles_id === profileId,
      )
    ) {
      await acceptFriendRequest(profileId);
      await getFriends();
    } else {
      void createFriendRequest(profileId);
    }
  };

  const blockButtonOnPress = () => {
    if (profileBlocked) {
      void unblockProfile(profileId);
    } else void blockProfile(profileId);
  };

  return (
    <ScreenWrapper>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <LoadingButton
          appearance="ghost"
          text="Back"
          onPress={() =>
            navigation.canGoBack()
              ? navigation.goBack()
              : navigation.navigate('Tabs', { screen: 'Friends' })
          }
          accessoryLeft={(props) => (
            <Icon {...props} name="arrow-back-outline" />
          )}
        />
        <View style={{ flexDirection: 'row' }}>
          <LoadingButton
            size="small"
            appearance="ghost"
            text={blockButtonText()}
            onPress={blockButtonOnPress}
            isLoading={blockedUsersLoading}
          />
          <LoadingButton
            size="small"
            text={friendButtonText()}
            onPress={friendButtonOnPress}
            disabled={
              (!!sentFriendRequests?.find(
                (request) => request.to_profiles_id === profileId,
              ) ??
                true) ||
              profileBlocked
            }
            isLoading={friendsLoading || friendRequestsLoading}
          />
        </View>
      </View>
      {!profileLoading && userProfile && <UserProfile {...userProfile} />}
      <Divider style={{ marginVertical: 12 }} />
      {profileLoading && <Spinner size="giant" />}
      {!profileLoading &&
        userProfile &&
        posts.map((post, index) => <Post key={index} {...post} />)}
    </ScreenWrapper>
  );
}

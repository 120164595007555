import { Divider, Text } from '@ui-kitten/components';
import { View } from 'react-native';
import useImageStorage from '../hooks/useImageStorage';
import { RawCommentType } from '../hooks/useComments';
import commonStyles from '../utils/commonStyles';
import UserHeader from './UserHeader';

type CommentProps = RawCommentType;

export default function Comment({
  content,
  profiles: { name, avatar_url },
}: CommentProps) {
  const { downloading, url: avatarUrl } = useImageStorage(
    avatar_url,
    'avatars',
  );

  return (
    <View style={{ padding: 8 }}>
      <Divider style={{ marginBottom: 8 }} />
      <UserHeader name={name} avatarUrl={avatarUrl} downloading={downloading} />
      <Text style={commonStyles().topSpacing}>{content}</Text>
    </View>
  );
}

import { View } from 'react-native';
import { Spinner } from '@ui-kitten/components';

export default function FullScreenSpinner() {
  return (
    <View
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Spinner size="giant" style={{ margin: 'auto' }} />
    </View>
  );
}

import {
  Autocomplete as AutocompleteComponent,
  AutocompleteItem,
} from '@ui-kitten/components';
import { useEffect, useState } from 'react';
import commonStyles from '../utils/commonStyles';
import stateCodes from '../utils/usStateCodes';

export interface State {
  state: string;
}

interface AutocompleteProps {
  onSelectItem: (item: State) => void;
  defaultState?: string;
}

const filter = (item: State, query: string) =>
  item.state.toLowerCase().includes(query.toLowerCase());

export default function StateAutocomplete({
  onSelectItem,
  defaultState,
}: AutocompleteProps) {
  const [value, setValue] = useState<string>();
  const [filteredData, setFilteredData] = useState(stateCodes);

  const onSelect = (index: number) => {
    setValue(filteredData[index].state);
    onSelectItem(filteredData[index]);
  };

  const onChangeText = (query: string) => {
    setValue(query);
    setFilteredData(stateCodes.filter((item) => filter(item, query)));
  };

  useEffect(() => {
    if (defaultState && !value) {
      setValue(defaultState);
      onSelectItem({ state: defaultState });
    }
  }, [defaultState, onSelectItem, value]);

  const renderOption = (item: State, index: number) => (
    <AutocompleteItem key={index} title={item.state} />
  );

  return (
    <AutocompleteComponent
      style={commonStyles().formElement}
      placeholder="State"
      value={value}
      onSelect={onSelect}
      onChangeText={onChangeText}
    >
      {filteredData.map(renderOption)}
    </AutocompleteComponent>
  );
}

import usePosts from '../../../hooks/usePosts';
import Post from '../../../components/Post';
import ScreenWrapper from '../../../components/ScreenWrapper';
import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';
import { Spinner } from '@ui-kitten/components';

export default function FeedScreen() {
  const { posts, getPosts, postsLoading } = usePosts();

  useFocusEffect(
    useCallback(() => {
      void getPosts();
    }, [getPosts]),
  );

  return (
    <ScreenWrapper isTabScreen>
      {postsLoading && <Spinner />}
      {posts.map((post, index) => (
        <Post key={index} {...post} />
      ))}
    </ScreenWrapper>
  );
}

import { AuthStackScreenProps } from '../../navigation/utils/types';
import { useState } from 'react';
import { supabase } from '../../utils/supabase';
import TextField from '../../components/TextField';
import LoadingButton from '../../components/LoadingButton';
import ScreenWrapper from '../../components/ScreenWrapper';
import { Text } from '@ui-kitten/components';
import { Platform, View } from 'react-native';
import LegalFooter from '../../components/LegalFooter';

export default function LoginScreen({
  navigation,
}: AuthStackScreenProps<'Login'>) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const signInWithEmail = async () => {
    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error) {
      navigation.navigate('AlertModal', {
        title: 'Error Logging In',
        subtitle: error.message,
      });
    }
    setLoading(false);
  };

  const sendForgotPasswordEmail = async () => {
    if (email === '') {
      navigation.navigate('AlertModal', {
        title: 'Error sending password reset link',
        subtitle: 'Please type in your email into the email field',
      });
    } else {
      const { error } = await supabase.auth.resetPasswordForEmail(email);
      if (error) {
        navigation.navigate('AlertModal', {
          title: 'Error resetting password',
          subtitle: error.message,
        });
      } else {
        navigation.navigate('AlertModal', {
          title: 'Password reset link sent',
          subtitle: 'Check your email for a password reset link.',
        });
      }
    }
  };

  return (
    <ScreenWrapper>
      {Platform.OS !== 'web' && (
        <View style={{ padding: 12, borderBottomWidth: 1, marginBottom: 12 }}>
          <Text category="h4">friend_refresh</Text>
        </View>
      )}
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        <View>
          <TextField label="Email" value={email} onChangeText={setEmail} />
          <TextField
            label="Password"
            value={password}
            onChangeText={setPassword}
            secureTextEntry
          />
          <LoadingButton
            isLoading={loading}
            text="Login"
            onPress={signInWithEmail}
            disabled={email === '' || password === ''}
          />
          <LoadingButton
            text="Don't have an account? Register now!"
            appearance="ghost"
            disabled={loading}
            onPress={() => navigation.navigate('Register')}
          />
          <LoadingButton
            text="Forgot password?"
            appearance="ghost"
            disabled={loading || email === ''}
            onPress={sendForgotPasswordEmail}
          />
        </View>
        <LegalFooter />
      </View>
    </ScreenWrapper>
  );
}

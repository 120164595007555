import { Image } from 'react-native';
import useImageStorage from '../hooks/useImageStorage';

interface PostImageProps {
  imageUrl: string;
}

const PostImage = ({ imageUrl }: PostImageProps) => {
  const { url, downloading } = useImageStorage(imageUrl, 'post_media');

  if (downloading || url === '') {
    return <></>;
  }

  return (
    <Image
      style={{
        width: 250,
        height: 250,
        resizeMode: 'contain',
        borderRadius: 4,
        margin: 4,
      }}
      source={{ uri: url }}
    />
  );
};

export default PostImage;

import { Text } from '@ui-kitten/components';
import { AppStackScreenProps } from '../../navigation/utils/types';
import LoadingButton from '../../components/LoadingButton';
import ScreenWrapper from '../../components/ScreenWrapper';

export default function NotFoundScreen({
  navigation,
}: AppStackScreenProps<'NotFound'>) {
  return (
    <ScreenWrapper>
      <Text>Not Found</Text>
      <LoadingButton
        text={navigation.canGoBack() ? 'Back to last page' : 'Back to home'}
        onPress={() =>
          navigation.canGoBack()
            ? navigation.goBack()
            : navigation.navigate('Tabs', { screen: 'Create' })
        }
      />
    </ScreenWrapper>
  );
}

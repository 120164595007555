import { Card } from '@ui-kitten/components';
import useImageStorage from '../hooks/useImageStorage';
import UserHeader from './UserHeader';

type MiniProfilePreviewProps = {
  onPress: () => void;
  id: string;
  avatar_url: string;
  name: string;
};

export default function MiniProfilePreview({
  name,
  avatar_url,
  onPress,
}: MiniProfilePreviewProps) {
  const { downloading, url: avatarUrl } = useImageStorage(
    avatar_url,
    'avatars',
  );

  return (
    <Card style={{ marginVertical: 4 }} onPress={onPress}>
      <UserHeader name={name} avatarUrl={avatarUrl} downloading={downloading} />
    </Card>
  );
}

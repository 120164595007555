const usStateCodes = [
  { state: 'Alabama', country: 'us' },
  { state: 'Alaska', country: 'us' },
  { state: 'Arizona', country: 'us' },
  { state: 'Arkansas', country: 'us' },
  { state: 'California', country: 'us' },
  { state: 'Colorado', country: 'us' },
  { state: 'Connecticut', country: 'us' },
  { state: 'Delaware', country: 'us' },
  { state: 'District of Columbia', country: 'us' },
  { state: 'Florida', country: 'us' },
  { state: 'Georgia', country: 'us' },
  { state: 'Hawaii', country: 'us' },
  { state: 'Idaho', country: 'us' },
  { state: 'Illinois', country: 'us' },
  { state: 'Indiana', country: 'us' },
  { state: 'Iowa', country: 'us' },
  { state: 'Kansas', country: 'us' },
  { state: 'Kentucky', country: 'us' },
  { state: 'Louisiana', country: 'us' },
  { state: 'Maine', country: 'us' },
  { state: 'Maryland', country: 'us' },
  { state: 'Massachusetts', country: 'us' },
  { state: 'Michigan', country: 'us' },
  { state: 'Minnesota', country: 'us' },
  { state: 'Mississippi', country: 'us' },
  { state: 'Missouri', country: 'us' },
  { state: 'Montana', country: 'us' },
  { state: 'Nebraska', country: 'us' },
  { state: 'Nevada', country: 'us' },
  { state: 'New Hampshire', country: 'us' },
  { state: 'New Jersey', country: 'us' },
  { state: 'New Mexico', country: 'us' },
  { state: 'New York', country: 'us' },
  { state: 'North Carolina', country: 'us' },
  { state: 'North Dakota', country: 'us' },
  { state: 'Ohio', country: 'us' },
  { state: 'Oklahoma', country: 'us' },
  { state: 'Oregon', country: 'us' },
  { state: 'Pennsylvania', country: 'us' },
  { state: 'Rhode Island', country: 'us' },
  { state: 'South Carolina', country: 'us' },
  { state: 'South Dakota', country: 'us' },
  { state: 'Tennessee', country: 'us' },
  { state: 'Texas', country: 'us' },
  { state: 'Utah', country: 'us' },
  { state: 'Vermont', country: 'us' },
  { state: 'Virginia', country: 'us' },
  { state: 'Washington', country: 'us' },
  { state: 'West Virginia', country: 'us' },
  { state: 'Wisconsin', country: 'us' },
  { state: 'Wyoming', country: 'us' },
];

export default usStateCodes;

import React, { useEffect, useState } from 'react';
import RenderHtml from 'react-native-render-html';
import { Text, useWindowDimensions } from 'react-native';
import ScreenWrapper from '../../components/ScreenWrapper';
import { supabase } from '../../utils/supabase';
import FullScreenSpinner from '../../components/FullScreenSpinner';
import { handlePostgrestError } from '../../utils/handlePostgrestError';
import { AuthStackScreenProps } from '../../navigation/utils/types';
import { Icon } from '@ui-kitten/components';
import LoadingButton from '../../components/LoadingButton';

export default function Legal({
  route,
  navigation,
}: AuthStackScreenProps<'privacy' | 'terms'>) {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getContent = async () => {
      try {
        setLoading(true);
        setError(null);

        const { data, error, status } = await supabase
          .from('legal')
          .select(
            `
              content
              `,
          )
          .eq('name', route.name)
          .single();

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          setContent(data.content as string);
        }
      } catch (error) {
        setError(handlePostgrestError(error).message);
      } finally {
        setLoading(false);
      }
    };
    void getContent();
  }, [route.name]);

  return (
    <ScreenWrapper>
      <LoadingButton
        appearance="ghost"
        text="Back"
        onPress={() => navigation.goBack()}
        accessoryLeft={(props) => <Icon {...props} name="arrow-back-outline" />}
      />
      {loading && <FullScreenSpinner />}
      <RenderHtml
        contentWidth={width}
        baseStyle={{
          color: 'white',
        }}
        source={{ html: content }}
        enableExperimentalMarginCollapsing
      />
      {error !== null && <Text>{error}</Text>}
    </ScreenWrapper>
  );
}

import useImageStorage from '../hooks/useImageStorage';
import { Profile } from '../hooks/useProfile';
import UserHeader from './UserHeader';

type UserProfileProps = Profile;

export default function UserProfile({
  name,
  avatar_url,
  country_code: countryCode,
  us_state,
}: UserProfileProps) {
  const { downloading, url: avatarUrl } = useImageStorage(
    avatar_url,
    'avatars',
  );

  return (
    <UserHeader
      name={name}
      avatarUrl={avatarUrl}
      downloading={downloading}
      countryCode={countryCode}
      usState={us_state}
    />
  );
}

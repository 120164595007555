import { Card, Modal, Text } from '@ui-kitten/components';
import LoadingButton from '../../components/LoadingButton';
import ScreenWrapper from '../../components/ScreenWrapper';
import { AuthStackScreenProps } from '../../navigation/utils/types';

export default function AlertModal({
  navigation,
  route: {
    params: { title, subtitle },
  },
}: AuthStackScreenProps<'AlertModal'> | AuthStackScreenProps<'AlertModal'>) {
  return (
    <ScreenWrapper>
      <Modal visible>
        <Card>
          <Text category="h6">{title}</Text>
          <Text>{subtitle}</Text>
          <LoadingButton text="Okay" onPress={() => navigation.goBack()} />
        </Card>
      </Modal>
    </ScreenWrapper>
  );
}

const linking = {
  prefixes: ['friendrefresh.com'],
  config: {
    screens: {
      Tabs: {
        path: '',
        screens: {
          Create: '',
          Profile: 'profile',
          Feed: 'feed',
          Friends: 'friends',
        },
      },
      UserProfile: 'user-profile',
      Post: 'post',
      Settings: 'settings',
      NotFound: '*',
      Login: 'login',
      Register: 'register',
      Welcome: 'welcome',
      AlertModal: 'alert',
      privacy: 'privacy',
      terms: 'terms',
      UpdatePassword: 'update-password',
    },
  },
};

export default linking;

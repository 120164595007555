import { Text } from '@ui-kitten/components';
import { Pressable, View } from 'react-native';
import { countryCodeToCountry } from '../utils/countries';
import { formatTimestamp } from '../utils/dateUtils';
import Avatar from './Avatar';

interface UserHeaderProps {
  avatarUrl: string;
  downloading: boolean;
  name: string;
  createdAt?: string;
  countryCode?: string;
  usState?: string;
  onPress?: () => void;
}

export default function UserHeader({
  avatarUrl,
  downloading,
  name,
  createdAt,
  countryCode,
  usState = '',
  onPress,
}: UserHeaderProps) {
  return (
    <Pressable
      onPress={onPress}
      style={{
        flexDirection: 'column',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <Avatar avatarUrl={avatarUrl} isLoading={downloading} />
        <View
          style={{
            marginLeft: 12,
          }}
        >
          <Text category="h6">{name}</Text>
          {createdAt && (
            <Text category="label">{formatTimestamp(createdAt)}</Text>
          )}
          <Text category="c2">
            {countryCode ? countryCodeToCountry(countryCode)?.name : ''}
          </Text>
          <Text category="c2">{usState}</Text>
        </View>
      </View>
    </Pressable>
  );
}

import { mapping as defaultMapping } from '@eva-design/material';

const mapping = {
  ...defaultMapping,
  strict: {
    'text-font-family': 'Inter_400Regular',
    'text-heading-1-font-size': 40,
    'text-heading-1-font-weight': '800',
    'text-heading-1-font-family': 'Inter_400Regular',
    'text-heading-2-font-size': 36,
    'text-heading-2-font-weight': '800',
    'text-heading-2-font-family': 'Inter_400Regular',
    'text-heading-3-font-size': 34,
    'text-heading-3-font-weight': '800',
    'text-heading-3-font-family': 'Inter_400Regular',
    'text-heading-4-font-size': 30,
    'text-heading-4-font-weight': '800',
    'text-heading-4-font-family': 'Inter_400Regular',
    'text-heading-5-font-size': 28,
    'text-heading-5-font-weight': '800',
    'text-heading-5-font-family': 'Inter_400Regular',
    'text-heading-6-font-size': 22,
    'text-heading-6-font-weight': '800',
    'text-heading-6-font-family': 'Inter_400Regular',
    'text-subtitle-1-font-size': 19,
    'text-subtitle-1-font-weight': '600',
    'text-subtitle-1-font-family': 'Inter_400Regular',
    'text-subtitle-2-font-size': 17,
    'text-subtitle-2-font-weight': '600',
    'text-subtitle-2-font-family': 'Inter_400Regular',
    'text-paragraph-1-font-size': 19,
    'text-paragraph-1-font-weight': '400',
    'text-paragraph-1-font-family': 'Inter_400Regular',
    'text-paragraph-2-font-size': 17,
    'text-paragraph-2-font-weight': '400',
    'text-paragraph-2-font-family': 'Inter_400Regular',
    'text-caption-1-font-size': 16,
    'text-caption-1-font-weight': '400',
    'text-caption-1-font-family': 'Inter_400Regular',
    'text-caption-2-font-size': 16,
    'text-caption-2-font-weight': '600',
    'text-caption-2-font-family': 'Inter_400Regular',
    'text-label-font-size': 16,
    'text-label-font-weight': '800',
    'text-label-font-family': 'Inter_400Regular',
    'size-tiny': 28,
    'size-small': 36,
    'size-medium': 44,
    'size-large': 52,
    'size-giant': 60,
    'border-radius': 4,
    'border-width': 1,
  },
} as unknown;

export default mapping;

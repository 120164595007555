import { Text } from '@ui-kitten/components';

import { AppStackScreenProps } from '../../navigation/utils/types';
import LoadingButton from '../../components/LoadingButton';
import ScreenWrapper from '../../components/ScreenWrapper';

export default function SettingsScreen({
  navigation,
}: AppStackScreenProps<'Settings'>) {
  return (
    <ScreenWrapper>
      <Text>Settings Screen</Text>
      <LoadingButton
        text="Back to Create"
        onPress={() =>
          navigation.canGoBack()
            ? navigation.goBack()
            : navigation.navigate('Tabs', { screen: 'Create' })
        }
      />
    </ScreenWrapper>
  );
}

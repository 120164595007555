import { useNavigation } from '@react-navigation/native';
import { Card, Text } from '@ui-kitten/components';
import { useContext } from 'react';
import { View } from 'react-native';
import { SessionContext } from '../contexts/SessionContext';
import useImageStorage from '../hooks/useImageStorage';
import usePosts, { RawPostType } from '../hooks/usePosts';
import commonStyles from '../utils/commonStyles';
import { handlePostgrestError } from '../utils/handlePostgrestError';
import { supabase } from '../utils/supabase';
import LoadingButton from './LoadingButton';
import PostImage from './PostImage';
import UserHeader from './UserHeader';

type PostProps = RawPostType & {
  variant?: 'Feed' | 'Screen';
};

export default function Post({
  content,
  created_at,
  profiles: { name, avatar_url },
  id,
  commentCount,
  media_urls,
  variant = 'Feed',
  profile_id,
}: PostProps) {
  const { url: avatarUrl, downloading } = useImageStorage(
    avatar_url,
    'avatars',
  );
  const navigation = useNavigation();
  const session = useContext(SessionContext);
  const { deletePost } = usePosts();

  const reportPost = async () => {
    if (session) {
      try {
        const { error } = await supabase.from('reports').insert({
          post_id: id,
          profile_id: session.user.id,
        });

        if (error) {
          navigation.navigate('AlertModal', {
            title: 'Failed to report post!',
            subtitle: handlePostgrestError(error).message,
          });
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to report post!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        navigation.navigate('AlertModal', {
          title: 'Post reported!',
          subtitle: 'Post will be reviewed shortly.',
        });
      }
    }
  };

  return (
    <Card style={variant === 'Feed' ? { margin: 4 } : {}}>
      <UserHeader
        onPress={() =>
          navigation.navigate('UserProfile', { profileId: profile_id })
        }
        createdAt={created_at}
        name={name}
        avatarUrl={avatarUrl}
        downloading={downloading}
      />
      <Text style={commonStyles().topSpacing}>{content}</Text>
      {media_urls && media_urls.length > 0 && (
        <View style={{ marginTop: 12 }}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {media_urls.map((mediaUrl, index) => (
              <PostImage key={index} imageUrl={mediaUrl} />
            ))}
          </View>
        </View>
      )}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginTop: 16,
        }}
      >
        {profile_id === session?.user.id ? (
          <LoadingButton
            text="Remove"
            size="medium"
            appearance="ghost"
            onPress={() => deletePost(id)}
          />
        ) : (
          <LoadingButton
            text="Report"
            size="medium"
            appearance="ghost"
            onPress={reportPost}
          />
        )}
        {variant === 'Feed' && (
          <LoadingButton
            text={`Comments (${commentCount})`}
            size="medium"
            style={{ flex: 1 }}
            onPress={() => navigation.navigate('Post', { postId: id })}
          />
        )}
      </View>
    </Card>
  );
}

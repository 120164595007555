import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

const commonStyles = (isTabScreen = false) =>
  StyleSheet.create({
    formElement: {
      margin: 8,
    },
    widthContainer: {
      maxWidth: 600,
      width: '100%',
      marginHorizontal: 'auto',
      marginBottom: 8,
      minHeight: Dimensions.get('window').height - (isTabScreen ? 156 : 100),
    },
    layout: {
      padding: 16,
      flex: 1,
    },
    flex1: {
      flex: 1,
    },
    topSpacing: {
      marginTop: 12,
    },
  });

export default commonStyles;

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import SettingsScreen from '../screens/app/SettingsScreen';
import { AppStackParamList } from './utils/types';
import TabNavigator from './TabNavigator';
import NotFoundScreen from '../screens/app/NotFoundScreen';
import PostScreen from '../screens/app/PostScreen';
import AlertModal from '../screens/generic/AlertModal';
import UserProfileScreen from '../screens/app/UserProfileScreen';
import Legal from '../screens/generic/Legal';
import ResetPasswordScreen from '../screens/app/UpdatePasswordScreen';

const AppStack = createNativeStackNavigator<AppStackParamList>();

export default function AppStackNavigator() {
  return (
    <AppStack.Navigator screenOptions={{ headerShown: false }}>
      <AppStack.Screen name="Tabs" component={TabNavigator} />
      <AppStack.Screen name="Settings" component={SettingsScreen} />
      <AppStack.Screen name="NotFound" component={NotFoundScreen} />
      <AppStack.Screen name="Post" component={PostScreen} />
      <AppStack.Screen name="AlertModal" component={AlertModal} />
      <AppStack.Screen name="UserProfile" component={UserProfileScreen} />
      <AppStack.Screen name="UpdatePassword" component={ResetPasswordScreen} />
      <AppStack.Screen name="privacy" component={Legal} />
      <AppStack.Screen name="terms" component={Legal} />
    </AppStack.Navigator>
  );
}

import {
  BottomTabBarProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import { TabParamList } from './utils/types';
import CreatePost from '../screens/app/tabs/CreatePostScreen';
import FeedScreen from '../screens/app/tabs/FeedScreen';
import ProfileScreen from '../screens/app/tabs/ProfileScreen';
import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components';
import FriendsScreen from '../screens/app/tabs/FriendsScreen';

const Tab = createBottomTabNavigator<TabParamList>();

const BottomTabBar = ({ navigation, state }: BottomTabBarProps) => (
  <BottomNavigation
    selectedIndex={state.index}
    onSelect={(index) => navigation.navigate(state.routeNames[index])}
  >
    <BottomNavigationTab title="Create" />
    <BottomNavigationTab title="Feed" />
    <BottomNavigationTab title="Profile" />
    <BottomNavigationTab title="Friends" />
  </BottomNavigation>
);

export default function TabNavigator() {
  return (
    <Tab.Navigator
      screenOptions={{ headerShown: false }}
      tabBar={(props) => <BottomTabBar {...props} />}
    >
      <Tab.Screen name="Create" component={CreatePost} />
      <Tab.Screen name="Feed" component={FeedScreen} />
      <Tab.Screen name="Profile" component={ProfileScreen} />
      <Tab.Screen name="Friends" component={FriendsScreen} />
    </Tab.Navigator>
  );
}

import { useNavigation } from '@react-navigation/native';
import { useState, useEffect, useContext, useCallback } from 'react';
import { SessionContext } from '../contexts/SessionContext';
import { handlePostgrestError } from '../utils/handlePostgrestError';
import { supabase } from '../utils/supabase';

export interface RawCommentType {
  id: string;
  profile_id: string;
  post_id: string;
  updated_at: string;
  created_at: string;
  content: string;
  profiles: {
    name: string;
    avatar_url: string;
  };
}

const useComments = (postId: string) => {
  const [comments, setComments] = useState<RawCommentType[]>([]);

  const [commentsLoading, setLoading] = useState(true);

  const session = useContext(SessionContext);

  const navigation = useNavigation();

  const getComments = useCallback(async () => {
    if (session) {
      try {
        setComments([]);
        setLoading(true);

        const { data, error, status } = await supabase
          .from('comments')
          .select(
            `
            id,
            profile_id,
            content,
            created_at,
            updated_at,
            post_id,
            profiles (
              name,
              avatar_url
            )
            `,
          )
          .eq('post_id', postId)
          .order('created_at');

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          setComments(
            data.map((comment) => {
              const name = Array.isArray(comment.profiles)
                ? (comment.profiles?.[0].name as string)
                : (comment.profiles?.name as string);
              const avatar_url = Array.isArray(comment.profiles)
                ? (comment.profiles?.[0].avatar_url as string)
                : (comment.profiles?.avatar_url as string);
              return {
                ...comment,
                profiles: {
                  name,
                  avatar_url,
                },
              };
            }),
          );
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to get comments!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [postId, session, navigation]);

  const addComment = async (content: string) => {
    if (session) {
      try {
        setLoading(true);
        const { error } = await supabase
          .from('comments')
          .insert({ content, profile_id: session.user.id, post_id: postId });

        if (error) {
          throw error;
        } else {
          await getComments();
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to create your comment!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteComment = async (id: string) => {
    if (session) {
      try {
        setLoading(true);
        const { error } = await supabase.from('comments').delete().eq('id', id);

        if (error) {
          throw error;
        } else {
          await getComments();
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to delete your comment!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const updateComment = async (commentId: string, content: string) => {
    if (session) {
      try {
        setLoading(true);

        const updates = {
          id: commentId,
          content: content,
          updated_at: new Date(),
        };

        const { error } = await supabase.from('comments').upsert(updates);

        if (error) {
          throw error;
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to update your comment!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void getComments();
  }, [getComments, postId]);

  return {
    comments,
    commentsLoading,
    addComment,
    getComments,
    deleteComment,
    updateComment,
  };
};

export default useComments;

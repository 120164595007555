import { useNavigation } from '@react-navigation/native';
import { Button } from '@ui-kitten/components';
import { View } from 'react-native';

export default function LegalFooter() {
  const navigation = useNavigation();
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 16,
      }}
    >
      <Button appearance="ghost" onPress={() => navigation.navigate('privacy')}>
        Privacy Policy
      </Button>
      <Button appearance="ghost" onPress={() => navigation.navigate('terms')}>
        Terms & Conditions
      </Button>
    </View>
  );
}

import { useNavigation } from '@react-navigation/native';
import { useState, useEffect, useContext, useCallback } from 'react';
import { SessionContext } from '../contexts/SessionContext';
import { handlePostgrestError } from '../utils/handlePostgrestError';
import { supabase } from '../utils/supabase';

export interface FriendRequests {
  from_profiles_id: string;
  to_profiles_id: string;
  is_accepted: boolean;
  created_at: string;
  from: {
    name: string;
    avatar_url: string;
  };
  to: {
    name: string;
    avatar_url: string;
  };
}

const useFriendRequests = () => {
  const [sentFriendRequests, setSentFriendRequests] = useState<
    FriendRequests[] | null
  >(null);
  const [receivedFriendRequests, setReceivedFriendRequests] = useState<
    FriendRequests[] | null
  >(null);
  const [friendRequestsLoading, setLoading] = useState(true);

  const navigation = useNavigation();
  const session = useContext(SessionContext);

  const getSentFriendRequests = useCallback(async () => {
    if (session) {
      try {
        setLoading(true);

        const { user } = session;

        const { data, error, status } = await supabase
          .from('friend_requests')
          .select(
            `
            from_profiles_id,
            to_profiles_id,
            is_accepted,
            created_at,
            from:from_profiles_id(name,avatar_url),
            to:to_profiles_id(name,avatar_url)
            `,
          )
          .eq('from_profiles_id', user.id)
          .is('is_accepted', null);

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          setSentFriendRequests(
            data.map((friend) => {
              const from_name = Array.isArray(friend.from)
                ? (friend.from?.[0].name as string)
                : (friend.from?.name as string);
              const to_name = Array.isArray(friend.to)
                ? (friend.to?.[0].name as string)
                : (friend.to?.name as string);
              const from_avatar = Array.isArray(friend.from)
                ? (friend.from?.[0].avatar_url as string)
                : (friend.from?.avatar_url as string);
              const to_avatar = Array.isArray(friend.to)
                ? (friend.to?.[0].avatar_url as string)
                : (friend.to?.avatar_url as string);
              return {
                ...friend,
                from: {
                  name: from_name,
                  avatar_url: from_avatar,
                },
                to: {
                  name: to_name,
                  avatar_url: to_avatar,
                },
              };
            }),
          );
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to get your Friend Requests!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [navigation, session]);

  const getReceivedFriendRequests = useCallback(async () => {
    if (session) {
      try {
        setLoading(true);

        const { user } = session;

        const { data, error, status } = await supabase
          .from('friend_requests')
          .select(
            `
            from_profiles_id,
            to_profiles_id,
            is_accepted,
            created_at,
            from:from_profiles_id(name,avatar_url),
            to:to_profiles_id(name,avatar_url)
            `,
          )
          .eq('to_profiles_id', user.id)
          .is('is_accepted', null);

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          setReceivedFriendRequests(
            data.map((friend) => {
              const from_name = Array.isArray(friend.from)
                ? (friend.from?.[0].name as string)
                : (friend.from?.name as string);
              const to_name = Array.isArray(friend.to)
                ? (friend.to?.[0].name as string)
                : (friend.to?.name as string);
              const from_avatar = Array.isArray(friend.from)
                ? (friend.from?.[0].avatar_url as string)
                : (friend.from?.avatar_url as string);
              const to_avatar = Array.isArray(friend.to)
                ? (friend.to?.[0].avatar_url as string)
                : (friend.to?.avatar_url as string);
              return {
                ...friend,
                from: {
                  name: from_name,
                  avatar_url: from_avatar,
                },
                to: {
                  name: to_name,
                  avatar_url: to_avatar,
                },
              };
            }),
          );
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to get your Friend Requests!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [navigation, session]);

  const createFriendRequest = async (toUserId: string) => {
    if (session) {
      try {
        setLoading(true);
        const { error } = await supabase
          .from('friend_requests')
          .insert({
            from_profiles_id: session.user.id,
            to_profiles_id: toUserId,
          })
          .select();

        if (error) {
          throw error;
        } else {
          await getSentFriendRequests();
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to create your friend request!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const acceptFriendRequest = async (from_id?: string) => {
    if (session && from_id) {
      try {
        setLoading(true);

        const updates = {
          from_profiles_id: from_id,
          is_accepted: true,
          to_profiles_id: session.user.id,
        };

        const { error } = await supabase
          .from('friend_requests')
          .update(updates);

        if (error) {
          throw error;
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to update your Friend Request!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void getSentFriendRequests();
    void getReceivedFriendRequests();
  }, [getSentFriendRequests, getReceivedFriendRequests]);

  return {
    sentFriendRequests,
    receivedFriendRequests,
    friendRequestsLoading,
    createFriendRequest,
    getSentFriendRequests,
    acceptFriendRequest,
  };
};

export default useFriendRequests;

import { View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import { AppStackScreenProps } from '../../navigation/utils/types';
import usePosts from '../../hooks/usePosts';
import Post from '../../components/Post';
import LoadingButton from '../../components/LoadingButton';
import useComments from '../../hooks/useComments';
import CommentInput from '../../components/CommentInput';
import Comment from '../../components/Comment';
import commonStyles from '../../utils/commonStyles';
import ScreenWrapper from '../../components/ScreenWrapper';

export default function PostScreen({
  navigation,
  route,
}: AppStackScreenProps<'Post'>) {
  const postId = route.params.postId;
  const { post } = usePosts(postId);
  const { comments, commentsLoading, getComments } = useComments(postId);

  return (
    <ScreenWrapper>
      <LoadingButton
        style={{ alignSelf: 'flex-start' }}
        appearance="ghost"
        text="Back"
        onPress={() =>
          navigation.canGoBack()
            ? navigation.goBack()
            : navigation.navigate('Tabs', { screen: 'Feed' })
        }
        accessoryLeft={(props) => <Icon {...props} name="arrow-back-outline" />}
      />
      {post && <Post variant="Screen" {...post} />}
      <View style={commonStyles().topSpacing}>
        <Text style={{ alignSelf: 'center', marginBottom: 12 }}>
          {comments.length === 0 && !commentsLoading
            ? 'No Comments...'
            : 'Comments'}
        </Text>
        {comments.map((comment, index) => (
          <Comment key={index} {...comment} />
        ))}
        <CommentInput postId={postId} onSubmit={() => void getComments()} />
      </View>
    </ScreenWrapper>
  );
}

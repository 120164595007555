import { useNavigation } from '@react-navigation/native';
import { useState, useEffect, useContext, useCallback } from 'react';
import { SessionContext } from '../contexts/SessionContext';
import { handlePostgrestError } from '../utils/handlePostgrestError';
import { supabase } from '../utils/supabase';

export interface Friends {
  from_profiles_id: string;
  to_profiles_id: string;
  is_accepted: boolean;
  created_at: string;
}

const useFriends = () => {
  const [friends, setFriends] = useState<Friends[] | null>(null);
  const [friendsLoading, setLoading] = useState(true);

  const navigation = useNavigation();
  const session = useContext(SessionContext);

  const getFriends = useCallback(async () => {
    if (session) {
      try {
        setLoading(true);

        const { user } = session;

        const { data, error, status } = await supabase
          .from('friend_requests')
          .select(
            `
            from_profiles_id,
            to_profiles_id,
            is_accepted,
            created_at
            `,
          )
          .or(`from_profiles_id.eq.${user.id},to_profiles_id.eq.${user.id}`)
          .eq('is_accepted', true);

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          setFriends(data);
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to get your Friend Requests!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [navigation, session]);

  const deleteFriend = async (profile_id?: string) => {
    if (session && profile_id) {
      try {
        setLoading(true);
        const { error } = await supabase
          .from('friend_requests')
          .delete()
          .or(
            `and(from_profiles_id.eq.${profile_id},to_profiles_id.eq.${session.user.id}),and(to_profiles_id.eq.${profile_id},from_profiles_id.eq.${session.user.id})`,
          );

        if (error) {
          throw error;
        } else {
          await getFriends();
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to remove friend!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void getFriends();
  }, [getFriends]);

  return {
    friends,
    friendsLoading,
    getFriends,
    deleteFriend,
  };
};

export default useFriends;

import { AppStackScreenProps } from '../../navigation/utils/types';
import { useState } from 'react';
import { supabase } from '../../utils/supabase';
import TextField from '../../components/TextField';
import LoadingButton from '../../components/LoadingButton';
import ScreenWrapper from '../../components/ScreenWrapper';
import { Text } from '@ui-kitten/components';
import { Platform, View } from 'react-native';
import LegalFooter from '../../components/LegalFooter';

export default function ResetPasswordScreen({
  navigation,
}: AppStackScreenProps<'UpdatePassword'>) {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const updateUserPassword = async () => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.updateUser({ password });

      if (error) {
        navigation.navigate('AlertModal', {
          title: 'Error Updating Password',
          subtitle: error.message,
        });
      }
    } catch (error) {
      navigation.navigate('AlertModal', {
        title: 'Failed to update password',
        subtitle: error as string,
      });
    } finally {
      setLoading(false);
      navigation.navigate('Tabs', { screen: 'Create' });
    }
  };

  return (
    <ScreenWrapper>
      {Platform.OS !== 'web' && (
        <View style={{ padding: 12, borderBottomWidth: 1, marginBottom: 12 }}>
          <Text category="h4">friend_refresh</Text>
        </View>
      )}
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        <View>
          <TextField
            label="New Password"
            value={password}
            onChangeText={setPassword}
            secureTextEntry
          />
          <LoadingButton
            isLoading={loading}
            text="Reset Password"
            onPress={updateUserPassword}
          />
        </View>
        <LegalFooter />
      </View>
    </ScreenWrapper>
  );
}

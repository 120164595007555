import { useNavigation } from '@react-navigation/native';
import { useState, useEffect, useContext, useCallback } from 'react';
import { SessionContext } from '../contexts/SessionContext';
import { handlePostgrestError } from '../utils/handlePostgrestError';
import { supabase } from '../utils/supabase';

export interface Profile {
  id: string;
  avatar_url: string;
  name: string;
  country_code: string;
  us_state: string;
  is_locked: boolean;
}

const useProfile = () => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [profilesLoading, setLoading] = useState(true);

  const navigation = useNavigation();
  const session = useContext(SessionContext);

  const getProfile = useCallback(async () => {
    if (session) {
      try {
        setLoading(true);

        const { user } = session;

        const { data, error, status } = await supabase
          .from('profiles')
          .select(
            `
            id,
            name,
            avatar_url,
            us_state,
            country_code,
            is_locked
            `,
          )
          .eq('id', user.id)
          .single();

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          setProfile(data);
          if (data.is_locked) {
            await supabase.auth.signOut();
          }
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to get your Profile!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [navigation, session]);

  const updateProfile = async (name: string, avatarUrl?: string) => {
    if (session) {
      try {
        setLoading(true);

        const updates = {
          id: session?.user.id,
          name: name,
          avatar_url: avatarUrl,
          updated_at: new Date(),
        };

        const updatesWithoutAvatar = {
          id: session?.user.id,
          name: name,
          updated_at: new Date(),
        };

        const { error } = await supabase
          .from('profiles')
          .upsert(avatarUrl ? updates : updatesWithoutAvatar);

        if (error) {
          throw error;
        } else {
          await getProfile();
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to update your Profile!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const lockProfile = async () => {
    if (session) {
      try {
        setLoading(true);

        const { error } = await supabase
          .from('profiles')
          .update({ is_locked: true })
          .eq('id', session.user.id);

        if (error) {
          throw error;
        } else {
          await getProfile();
          await supabase.auth.signOut();
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to delete your account!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const updateProfileLocation = async (
    countryCode: string,
    usState: string | null,
  ) => {
    if (session) {
      try {
        setLoading(true);

        const updates = {
          id: session?.user.id,
          country_code: countryCode,
          us_state: usState,
        };

        const { error } = await supabase.from('profiles').upsert(updates);

        if (error) {
          throw error;
        } else {
          await getProfile();
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to update your location!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void getProfile();
  }, [getProfile]);

  return {
    profile,
    profilesLoading,
    getProfile,
    updateProfile,
    updateProfileLocation,
    lockProfile,
  };
};

export default useProfile;

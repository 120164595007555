import { useNavigation } from '@react-navigation/native';
import { useState, useEffect, useContext, useCallback } from 'react';
import { SessionContext } from '../contexts/SessionContext';
import { handlePostgrestError } from '../utils/handlePostgrestError';
import { supabase } from '../utils/supabase';

export interface BlockedUsers {
  profile_id: string;
  blocked_profile_id: string;
  created_at: string;
}

const useBlocks = () => {
  const [blockedUsers, setBlockedUsers] = useState<BlockedUsers[] | null>(null);
  const [blockedUsersLoading, setLoading] = useState(true);

  const navigation = useNavigation();
  const session = useContext(SessionContext);

  const getBlockedUsers = useCallback(async () => {
    if (session) {
      try {
        setLoading(true);

        const { user } = session;

        const { data, error, status } = await supabase
          .from('blocks')
          .select(
            `
            profile_id,
            blocked_profile_id,
            created_at
            `,
          )
          .eq('profile_id', user.id);

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          setBlockedUsers(data);
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to get blocked users!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [navigation, session]);

  const blockProfile = async (blocked_profile_id?: string) => {
    if (session && blocked_profile_id) {
      try {
        setLoading(true);
        const { error: deleteFriendError } = await supabase
          .from('friend_requests')
          .delete()
          .or(
            `and(from_profiles_id.eq.${blocked_profile_id},to_profiles_id.eq.${session.user.id}),and(to_profiles_id.eq.${blocked_profile_id},from_profiles_id.eq.${session.user.id})`,
          );

        if (deleteFriendError) {
          throw deleteFriendError;
        }

        const { error } = await supabase
          .from('blocks')
          .insert({ profile_id: session.user.id, blocked_profile_id });

        if (error) {
          throw error;
        } else {
          void getBlockedUsers();
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to block user!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const unblockProfile = async (blocked_profile_id?: string) => {
    if (session && blocked_profile_id) {
      try {
        setLoading(true);
        const { error } = await supabase
          .from('blocks')
          .delete()
          .match({ profile_id: session.user.id, blocked_profile_id });

        if (error) {
          throw error;
        } else {
          void getBlockedUsers();
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to unblock user!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void getBlockedUsers();
  }, [getBlockedUsers]);

  return {
    blockedUsers,
    blockedUsersLoading,
    getBlockedUsers,
    blockProfile,
    unblockProfile,
  };
};

export default useBlocks;

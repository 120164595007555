import { Card } from '@ui-kitten/components';
import useImageStorage from '../hooks/useImageStorage';
import { Profile } from '../hooks/useProfile';
import UserHeader from './UserHeader';

type ProfilePreviewProps = Profile & {
  onPress: () => void;
};

export default function ProfilePreview({
  name,
  avatar_url,
  country_code: countryCode,
  us_state,
  onPress,
}: ProfilePreviewProps) {
  const { downloading, url: avatarUrl } = useImageStorage(
    avatar_url,
    'avatars',
  );

  return (
    <Card style={{ margin: 4 }} onPress={onPress}>
      <UserHeader
        name={name}
        avatarUrl={avatarUrl}
        downloading={downloading}
        countryCode={countryCode}
        usState={us_state}
        onPress={onPress}
      />
    </Card>
  );
}

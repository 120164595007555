import { dark } from '@eva-design/material';

const defaults = dark as Record<'', ''>;

const friendRefreshTheme = {
  ...defaults,
  'color-basic-100': '#E7EAFB',
  'color-basic-200': '#D0D6F7',
  'color-basic-300': '#B2BAE9',
  'color-basic-400': '#959DD4',
  'color-basic-500': '#6F78B8',
  'color-basic-600': '#51599E',
  'color-basic-700': '#373F84',
  'color-basic-800': '#23296A',
  'color-basic-900': '#151A58',
  'color-basic-1000': '#0d1140',
  'color-basic-1100': '#080b29',
  'color-basic-transparent-100': 'rgba(47, 54, 117, 0.20)',
  'color-basic-transparent-200': 'rgba(47, 54, 117, 0.28)',
  'color-basic-transparent-300': 'rgba(47, 54, 117, 0.36)',
  'color-basic-transparent-400': 'rgba(47, 54, 117, 0.44)',
  'color-basic-transparent-500': 'rgba(47, 54, 117, 0.52)',
  'color-basic-transparent-600': 'rgba(47, 54, 117, 0.60)',
  'color-primary-100': '#C7F9E7',
  'color-primary-200': '#92F3DA',
  'color-primary-300': '#58DCC5',
  'color-primary-400': '#2EB9AD',
  'color-primary-500': '#008B8B',
  'color-primary-600': '#006D77',
  'color-primary-700': '#005264',
  'color-primary-800': '#003B50',
  'color-primary-900': '#002B42',
  'color-success-100': '#D2F9CE',
  'color-success-200': '#A0F3A0',
  'color-success-300': '#6CDC77',
  'color-success-400': '#43BA5A',
  'color-success-500': '#158C38',
  'color-success-600': '#0F7838',
  'color-success-700': '#0A6436',
  'color-success-800': '#065132',
  'color-success-900': '#04432F',
  'color-info-100': '#CDEBFD',
  'color-info-200': '#9DD4FB',
  'color-info-300': '#6BB4F3',
  'color-info-400': '#4595E7',
  'color-info-500': '#0F69D8',
  'color-info-600': '#0A51B9',
  'color-info-700': '#073C9B',
  'color-info-800': '#042A7D',
  'color-info-900': '#021D67',
  'color-warning-100': '#FFF9CC',
  'color-warning-200': '#FFF099',
  'color-warning-300': '#FFE666',
  'color-warning-400': '#FFDC3F',
  'color-warning-500': '#FFCC00',
  'color-warning-600': '#DBAA00',
  'color-warning-700': '#B78B00',
  'color-warning-800': '#936D00',
  'color-warning-900': '#7A5700',
  'color-danger-100': '#FDD9D2',
  'color-danger-200': '#FCACA7',
  'color-danger-300': '#F67A7E',
  'color-danger-400': '#ED576C',
  'color-danger-500': '#E22450',
  'color-danger-600': '#C21A51',
  'color-danger-700': '#A2124F',
  'color-danger-800': '#830B49',
  'color-danger-900': '#6C0645',
};

export default friendRefreshTheme;

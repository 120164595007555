import { TabScreenProps } from '../../../navigation/utils/types';
import { useEffect, useState } from 'react';
import usePosts from '../../../hooks/usePosts';
import LoadingButton from '../../../components/LoadingButton';
import TextField from '../../../components/TextField';
import commonStyles from '../../../utils/commonStyles';
import ScreenWrapper from '../../../components/ScreenWrapper';
import useImageStorage from '../../../hooks/useImageStorage';
import { Image, Pressable, Text } from 'react-native';
import { Button, Card } from '@ui-kitten/components';
import { View } from 'react-native';
import useProfile from '../../../hooks/useProfile';
import { supabase } from '../../../utils/supabase';

export default function Create({ navigation }: TabScreenProps<'Create'>) {
  const [postText, setPostText] = useState('');
  const { addPost, postsLoading } = usePosts();
  const [imageUrl, setImageUrl] = useState('');
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [imageUrlsToUpload, setImageUrlsToUpload] = useState<string[]>([]);
  const { uploadImage, uploading, url, downloading } = useImageStorage(
    imageUrl,
    'post_media',
  );
  const { profile } = useProfile();

  useEffect(() => {
    if (profile?.is_locked) {
      void supabase.auth.signOut();
    }
  }, [profile]);

  useEffect(() => {
    if (url.length > 0 && !imageUrls.includes(url) && imageUrl.length > 0) {
      setImageUrls([...imageUrls, url]);
      setImageUrlsToUpload([...imageUrlsToUpload, imageUrl]);
    }
  }, [imageUrls, url, imageUrl]);

  const onAddPost = async () => {
    if (postText.length > 0) {
      const id = await addPost(postText, imageUrlsToUpload);
      if (id) {
        navigation.navigate('Post', { postId: id });
      }
    }
    setPostText('');
  };

  return (
    <ScreenWrapper isTabScreen>
      <TextField
        multiline
        value={postText}
        onChangeText={setPostText}
        textStyle={{ minHeight: 128 }}
        placeholder="Refresh your friends"
      />
      {imageUrls.length > 0 && (
        <Card style={commonStyles().formElement}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {imageUrls.map((image, index) => (
              <View key={index}>
                <Image
                  style={{
                    width: 250,
                    height: 250,
                    resizeMode: 'contain',
                    borderWidth: 1,
                    borderRadius: 4,
                    margin: 4,
                  }}
                  source={{ uri: image }}
                />
                <Button
                  appearance="ghost"
                  onPress={() => {
                    setImageUrl('');
                    setImageUrls(imageUrls.filter((img) => img !== image));
                  }}
                >
                  Remove
                </Button>
              </View>
            ))}
          </View>
        </Card>
      )}
      <LoadingButton
        style={commonStyles().topSpacing}
        text="Add Image"
        isLoading={uploading || downloading}
        disabled={imageUrls.length >= 4}
        onPress={async () => await uploadImage(setImageUrl)}
      />
      <LoadingButton
        style={commonStyles().topSpacing}
        text="Post"
        disabled={postText.length <= 3 || uploading || downloading}
        isLoading={postsLoading}
        onPress={onAddPost}
      />
    </ScreenWrapper>
  );
}

import { View } from 'react-native';
import { Button, ButtonProps, Spinner } from '@ui-kitten/components';
import { ImageProps } from 'react-native';
import commonStyles from '../utils/commonStyles';

type LoadingButtonProps = {
  text: string;
  isLoading?: boolean;
  disabled?: boolean;
  onPress: () => void | Promise<void>;
} & ButtonProps;

const LoadingIndicator = (props: Partial<ImageProps> | undefined) => (
  <View style={props?.style}>
    <Spinner size="small" />
  </View>
);

export default function LoadingButton({
  onPress,
  text,
  isLoading = false,
  disabled = false,
  ...props
}: LoadingButtonProps) {
  return (
    <Button
      accessoryLeft={isLoading ? LoadingIndicator : undefined}
      onPress={() => void onPress()}
      disabled={isLoading || disabled}
      {...props}
      style={[commonStyles().formElement, props.style]}
    >
      {text}
    </Button>
  );
}

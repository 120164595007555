import { AuthStackScreenProps } from '../../navigation/utils/types';
import { useState } from 'react';
import { supabase } from '../../utils/supabase';
import TextField from '../../components/TextField';
import LoadingButton from '../../components/LoadingButton';
import ScreenWrapper from '../../components/ScreenWrapper';
import LegalFooter from '../../components/LegalFooter';
import { Platform, View } from 'react-native';
import { Text } from '@ui-kitten/components';

export default function RegisterScreen({
  navigation,
}: AuthStackScreenProps<'Register'>) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  async function signUpWithEmail() {
    setLoading(true);
    const { error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        data: {
          email,
        },
      },
    });

    if (error) {
      navigation.navigate('AlertModal', {
        title: 'Error Registering',
        subtitle: error.message,
      });
    } else {
      navigation.navigate('AlertModal', {
        title: 'Success!',
        subtitle:
          'Please confirm your email by clicking on the link sent to it.',
      });
    }
    setLoading(false);
  }
  return (
    <ScreenWrapper>
      {Platform.OS !== 'web' && (
        <View style={{ padding: 12, borderBottomWidth: 1, marginBottom: 12 }}>
          <Text category="h4">friend_refresh</Text>
        </View>
      )}
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        <View>
          <TextField label="Email" value={email} onChangeText={setEmail} />
          <TextField
            label="Password"
            value={password}
            onChangeText={setPassword}
            secureTextEntry
          />
          <LoadingButton
            text="Register"
            isLoading={loading}
            onPress={signUpWithEmail}
            disabled={email === '' || password === ''}
          />
          <LoadingButton
            text="Have an account already? Go to login."
            appearance="ghost"
            disabled={loading}
            onPress={() => navigation.navigate('Login')}
          />
        </View>
        <LegalFooter />
      </View>
    </ScreenWrapper>
  );
}

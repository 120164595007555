import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AuthStackParamList } from './utils/types';
import RegisterScreen from '../screens/auth/RegisterScreen';
import LoginScreen from '../screens/auth/LoginScreen';
import AlertModal from '../screens/generic/AlertModal';
import Legal from '../screens/generic/Legal';

const AuthStack = createNativeStackNavigator<AuthStackParamList>();

export default function AuthStackNavigator() {
  return (
    <AuthStack.Navigator screenOptions={{ headerShown: false }}>
      <AuthStack.Screen name="Login" component={LoginScreen} />
      <AuthStack.Screen name="Register" component={RegisterScreen} />
      <AuthStack.Screen name="AlertModal" component={AlertModal} />
      <AuthStack.Screen name="privacy" component={Legal} />
      <AuthStack.Screen name="terms" component={Legal} />
    </AuthStack.Navigator>
  );
}

import { ApplicationProvider } from '@ui-kitten/components';
import * as eva from '@eva-design/material';
import { NavigationContainer } from '@react-navigation/native';
import linking from '../navigation/utils/linking';
import theme from '../utils/theme';
import mapping from '../utils/mapping';
import { Linking } from 'react-native';

const getInitialURL = async () => {
  const url = await Linking.getInitialURL();

  if (url != null) {
    return parseSupabaseUrl(url);
  }

  return url;
};

const subscribe = (listener: (url: string) => void) => {
  const onReceiveURL = ({ url }: { url: string }) => {
    listener(parseSupabaseUrl(url));
  };
  const subscription = Linking.addEventListener('url', onReceiveURL);

  return () => {
    subscription.remove();
  };
};

const parseSupabaseUrl = (url: string) => {
  let parsedUrl = url;
  if (url.includes('#')) {
    parsedUrl = url.replace('#', '?');
  }
  return parsedUrl;
};

interface ProvidersProps {
  children: JSX.Element | JSX.Element[];
}

interface SchemaType {
  version: number;
  strict?: StrictTheme;
  components: ThemeMappingType;
}
type ParameterType = string | number;

interface StrictTheme {
  [key: string]: ParameterType;
}
interface ThemeMappingType {
  [key: string]: never;
}

export default function Providers({ children }: ProvidersProps) {
  return (
    <NavigationContainer linking={{ ...linking, getInitialURL, subscribe }}>
      <ApplicationProvider
        {...eva}
        theme={theme}
        mapping={mapping as SchemaType}
      >
        {children}
      </ApplicationProvider>
    </NavigationContainer>
  );
}

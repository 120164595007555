import { Layout } from '@ui-kitten/components';
import { ScrollView, View } from 'react-native';
import commonStyles from '../utils/commonStyles';

interface ScreenWrapperProps {
  children: React.ReactNode | React.ReactNode[];
  isTabScreen?: boolean;
}

export default function ScreenWrapper({
  children,
  isTabScreen = false,
}: ScreenWrapperProps) {
  return (
    <ScrollView>
      <Layout style={commonStyles().layout}>
        <View style={commonStyles(isTabScreen).widthContainer}>{children}</View>
      </Layout>
    </ScrollView>
  );
}

import { useState } from 'react';
import useComments from '../hooks/useComments';
import LoadingButton from './LoadingButton';
import TextField from './TextField';
import commonStyles from '../utils/commonStyles';

type CommentInputProps = {
  postId: string;
  onSubmit: () => void;
};

export default function CommentInput({ postId, onSubmit }: CommentInputProps) {
  const [content, setContent] = useState('');
  const { addComment, commentsLoading } = useComments(postId);

  const onAddComment = async () => {
    await addComment(content);
    onSubmit();
    setContent('');
  };

  return (
    <>
      <TextField
        multiline
        value={content}
        onChangeText={setContent}
        textStyle={{ minHeight: 64 }}
        placeholder="Add a comment"
      />
      <LoadingButton
        style={commonStyles().topSpacing}
        text="Post comment"
        disabled={content.length <= 3}
        isLoading={commentsLoading}
        onPress={onAddComment}
      />
    </>
  );
}

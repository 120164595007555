import AsyncStorage from '@react-native-async-storage/async-storage';
import { setupURLPolyfill } from 'react-native-url-polyfill';
import { createClient } from '@supabase/supabase-js';
import { Platform } from 'react-native';

setupURLPolyfill();

const supabaseUrl = 'https://tteibowwxrqxbcfxtaer.supabase.co';
const supabaseAnonKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InR0ZWlib3d3eHJxeGJjZnh0YWVyIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njg4NzE1NjcsImV4cCI6MTk4NDQ0NzU2N30.gMMWZdCafvhc3Qt_NNZciMrNjD9zoi0NXxnxL6lDvek';

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    storage: AsyncStorage,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: Platform.OS === 'web',
  },
});

import { useNavigation } from '@react-navigation/native';
import { useState, useEffect, useContext, useCallback } from 'react';
import { SessionContext } from '../contexts/SessionContext';
import { handlePostgrestError } from '../utils/handlePostgrestError';
import { supabase } from '../utils/supabase';

export interface RawPostType {
  id: string;
  profile_id: string;
  updated_at: string;
  created_at: string;
  content: string;
  media_urls: string[];
  commentCount: string;
  profiles: {
    name: string;
    avatar_url: string;
  };
}

const usePosts = (postId?: string) => {
  const [posts, setPosts] = useState<RawPostType[]>([]);
  const [post, setPost] = useState<RawPostType | null>(null);
  const [postsLoading, setLoading] = useState(true);

  const navigation = useNavigation();
  const session = useContext(SessionContext);

  const getPost = useCallback(async () => {
    if (session && postId) {
      try {
        setLoading(true);

        const { data, error, status } = await supabase
          .from('posts')
          .select(
            `
            id,
            profile_id,
            content,
            created_at,
            updated_at,
            media_urls,
            comments (
              count
            ),
            profiles (
              name,
              avatar_url
            )
            `,
          )
          .eq('id', postId)
          .single();

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          const name = Array.isArray(data.profiles)
            ? (data.profiles?.[0].name as string)
            : (data.profiles?.name as string);
          const avatar_url = Array.isArray(data.profiles)
            ? (data.profiles?.[0].avatar_url as string)
            : (data.profiles?.avatar_url as string);
          const commentCount = Array.isArray(data.comments)
            ? (data.comments?.[0].count as string)
            : (data.comments?.count as string);
          setPost({
            ...data,
            commentCount,
            profiles: {
              name,
              avatar_url,
            },
          });
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to Post data!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [navigation, postId, session]);

  const getPosts = useCallback(async () => {
    if (session) {
      try {
        setLoading(true);

        const { data: friendsData } = await supabase
          .from('friend_requests')
          .select(
            `
          from_profiles_id,
          to_profiles_id
        `,
          )
          .or(
            `from_profiles_id.eq.${session.user.id},to_profiles_id.eq.${session.user.id}`,
          )
          .eq('is_accepted', true);

        if (friendsData) {
          const friends = friendsData
            .reduce(
              (
                prev: string[],
                cur: { from_profiles_id: string; to_profiles_id: string },
              ) => {
                return [...prev, cur.from_profiles_id, cur.to_profiles_id];
              },
              [],
            )
            .filter((item, index, arr) => arr.indexOf(item) === index);

          const { data, error, status } = await supabase
            .from('posts')
            .select(
              `
            id,
            profile_id,
            content,
            created_at,
            updated_at,
            media_urls,
            comments (
              count
            ),
            profiles (
              name,
              avatar_url,
              id
            )
            `,
            )
            .or(
              `profile_id.in.(${friends.toString()}),profile_id.eq.${
                session.user.id
              }`,
            )
            .order('created_at', { ascending: false })
            .limit(100);

          if (error && status !== 406) {
            throw error;
          }

          if (data) {
            setPosts(
              data.map((post) => {
                const name = Array.isArray(post.profiles)
                  ? (post.profiles?.[0].name as string)
                  : (post.profiles?.name as string);
                const avatar_url = Array.isArray(post.profiles)
                  ? (post.profiles?.[0].avatar_url as string)
                  : (post.profiles?.avatar_url as string);
                const commentCount = Array.isArray(post.comments)
                  ? (post.comments?.[0].count as string)
                  : (post.comments?.count as string);
                return {
                  ...post,
                  commentCount,
                  profiles: {
                    name,
                    avatar_url,
                  },
                };
              }),
            );
          }
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to update your Feed!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [navigation, session]);

  const addPost = async (content: string, imageUrls: string[]) => {
    if (session) {
      try {
        setLoading(true);
        const { error, data } = await supabase
          .from('posts')
          .insert({
            content,
            profile_id: session.user.id,
            media_urls: imageUrls,
          })
          .select();

        if (error) {
          throw error;
        } else {
          await getPosts();
          if (Array.isArray(data) && !!data[0]) {
            const temp = data[0] as { id: string };
            return temp.id;
          }
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to create your Post!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const deletePost = async (id: string) => {
    if (session) {
      try {
        setLoading(true);
        const { error } = await supabase.from('posts').delete().eq('id', id);

        if (error) {
          navigation.navigate('AlertModal', {
            title: 'Failed to delete your Post!',
            subtitle: handlePostgrestError(error).message,
          });
        } else {
          await getPosts();
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to delete your Post!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const updatePost = async (postId: string, content: string) => {
    if (session) {
      try {
        setLoading(true);

        const updates = {
          id: postId,
          content: content,
          updated_at: new Date(),
        };

        const { error } = await supabase.from('posts').upsert(updates);

        if (error) {
          throw error;
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to update your Post!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void getPosts();
    void getPost();
  }, [getPost, getPosts]);

  return {
    post,
    posts,
    postsLoading,
    addPost,
    getPosts,
    deletePost,
    updatePost,
  };
};

export default usePosts;

import { SafeAreaView, View, StatusBar, Platform } from 'react-native';
import Providers from './src/components/Providers';
import { IconRegistry, Layout, Text } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import AppStackNavigator from './src/navigation/AppStackNavigator';
import AuthStackNavigator from './src/navigation/AuthStackNavigator';
import { Session } from '@supabase/supabase-js';
import { useState, useEffect } from 'react';
import { supabase } from './src/utils/supabase';
import {
  useFonts,
  Inter_100Thin,
  Inter_200ExtraLight,
  Inter_300Light,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from '@expo-google-fonts/inter';
import { SessionContext } from './src/contexts/SessionContext';

export default function App() {
  const [session, setSession] = useState<Session | null>();
  const [fontsLoaded] = useFonts({
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });

  useEffect(() => {
    const getSession = async () => {
      const sessionResponse = await supabase.auth.getSession();
      setSession(sessionResponse.data.session);
    };
    void getSession();
    supabase.auth.onAuthStateChange((_event, ses) => {
      if (_event === 'SIGNED_OUT') {
        setSession(null);
      } else if (_event === 'SIGNED_IN' && !session) {
        setSession(ses);
      }
    });
  }, []);

  if (!fontsLoaded || session === undefined) {
    return <></>;
  }
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#23296A' }}>
      <SessionContext.Provider value={session}>
        <Providers>
          <IconRegistry icons={EvaIconsPack} />
          <Layout style={{ flex: 1 }}>
            {Platform.OS === 'web' && (
              <View style={{ padding: 12, borderBottomWidth: 1 }}>
                <Text category="h4">friend_refresh</Text>
              </View>
            )}
            <StatusBar barStyle="light-content" />
            {session?.user ? <AppStackNavigator /> : <AuthStackNavigator />}
          </Layout>
        </Providers>
      </SessionContext.Provider>
    </SafeAreaView>
  );
}

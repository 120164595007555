import useProfile from '../../../hooks/useProfile';
import { useEffect, useState } from 'react';
import { supabase } from '../../../utils/supabase';
import LoadingButton from '../../../components/LoadingButton';
import TextField from '../../../components/TextField';
import useImageStorage from '../../../hooks/useImageStorage';
import Avatar from '../../../components/Avatar';
import ScreenWrapper from '../../../components/ScreenWrapper';
import CountryAutocomplete, {
  Country,
} from '../../../components/CountryAutocomplete';
import StateAutocomplete from '../../../components/StateAutocomplete';
import LegalFooter from '../../../components/LegalFooter';

export default function ProfileScreen() {
  const {
    profile,
    updateProfile,
    profilesLoading,
    updateProfileLocation,
    lockProfile,
  } = useProfile();
  const [name, setName] = useState('');

  const [country, setCountry] = useState<Country>();
  const [state, setState] = useState<string | null>(null);

  const { url, downloading, uploadImage, uploading } = useImageStorage(
    profile?.avatar_url || '',
    'avatars',
  );

  useEffect(() => {
    setName(profile?.name || '');
    setState(profile?.us_state || null);
  }, [profile]);

  const onUpdateProfile = () => {
    void updateProfile(name);
  };

  const onUpdateLocation = () => {
    if (country) {
      void updateProfileLocation(country?.code, state);
    }
  };

  return (
    <ScreenWrapper isTabScreen>
      <Avatar
        style={{ alignSelf: 'center' }}
        size={128}
        isLoading={uploading || downloading}
        avatarUrl={url}
        disabled={uploading || downloading}
        onPress={async () =>
          await uploadImage(
            async (filePath) => await updateProfile(name, filePath),
          )
        }
      />
      <TextField label="name" onChangeText={setName} value={name} />
      <LoadingButton
        text="Save Name"
        onPress={onUpdateProfile}
        disabled={name.length < 3 || name === profile?.name}
        isLoading={profilesLoading}
      />
      <CountryAutocomplete
        defaultCountryCode={profile?.country_code}
        onSelectItem={(item) => setCountry(item)}
      />
      {country && country.code === 'us' && (
        <StateAutocomplete
          defaultState={profile?.us_state}
          onSelectItem={(item) => setState(item.state)}
        />
      )}
      <LoadingButton
        text="Save Location"
        onPress={onUpdateLocation}
        disabled={
          !country ||
          (country.code === profile?.country_code &&
            profile?.us_state === state)
        }
        isLoading={profilesLoading}
      />
      <LoadingButton
        text="Log Out"
        appearance="ghost"
        status="warning"
        onPress={() => void supabase.auth.signOut()}
      />
      <LoadingButton
        text="Delete Account"
        status="danger"
        appearance="ghost"
        onPress={() => lockProfile()}
      />
      <LegalFooter />
    </ScreenWrapper>
  );
}

import { Input, InputProps } from '@ui-kitten/components';
import commonStyles from '../utils/commonStyles';

type TextFieldProps = InputProps;

export default function TextField(props: TextFieldProps) {
  return (
    <Input
      size="large"
      {...props}
      style={[commonStyles().formElement, props.style]}
    />
  );
}

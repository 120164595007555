import { Pressable, StyleProp, View, ViewStyle } from 'react-native';
import { Avatar as AvatarComponent, Spinner } from '@ui-kitten/components';

interface AvatarProps {
  avatarUrl: string;
  isLoading?: boolean;
  disabled?: boolean;
  onPress?: () => void | Promise<void>;
  size?: number;
  style?: StyleProp<ViewStyle>;
}

export default function Avatar({
  onPress,
  avatarUrl,
  isLoading = false,
  disabled = false,
  size = 48,
  style = {},
}: AvatarProps) {
  return (
    <View style={style}>
      {!isLoading ? (
        <Pressable
          disabled={disabled || !onPress}
          onPress={() => !disabled && onPress && void onPress()}
        >
          <AvatarComponent
            style={{ height: size, width: size, borderRadius: 1000 }}
            source={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              avatarUrl === ''
                ? require('../assets/default-profile.png')
                : { uri: avatarUrl }
            }
          />
        </Pressable>
      ) : (
        <Spinner />
      )}
    </View>
  );
}

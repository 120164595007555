import { useCallback, useEffect, useState } from 'react';
import { supabase } from '../utils/supabase';
import { Alert } from 'react-native';
import * as ImagePicker from 'expo-image-picker';

const useImageStorage = (path: string, bucket: 'avatars' | 'post_media') => {
  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [url, setUrl] = useState('');

  const downloadImage = useCallback(async () => {
    try {
      setDownloading(true);
      const { data, error } = await supabase.storage
        .from(bucket)
        .download(path);

      if (error) {
        throw error;
      }

      const fr = new FileReader();
      fr.readAsDataURL(data);
      fr.onload = () => {
        setUrl(fr.result as string);
        setDownloading(false);
      };
    } catch (error) {
      if (error instanceof Error) {
        console.log('Error downloading image: ', error.message);
      }
    }
  }, [bucket, path]);

  useEffect(() => {
    if (!!path && path.length > 0) {
      void downloadImage();
    } else {
      setUrl('');
    }
  }, [downloadImage, path]);

  const uploadImage = async (
    onUpload: (filePath: string) => Promise<void> | void,
  ) => {
    try {
      const file = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        allowsMultipleSelection: false,
        aspect: [4, 4],
        quality: 1,
      });

      setUploading(true);

      if (file.assets?.length === 1) {
        const response = await fetch(file.assets[0].uri);
        const blob = await response.blob();

        const fileExt = file.assets[0].fileName?.split('.').pop() ?? 'png';
        const filePath = `${Math.random()}.${fileExt}`;

        const { error } = await supabase.storage
          .from(bucket)
          .upload(filePath, blob);

        if (error) {
          throw error;
        }

        await onUpload(filePath);
      }
    } catch (error) {
      if (error instanceof Error) {
        Alert.alert(error.message);
      }
    } finally {
      setUploading(false);
    }
  };

  return { downloading, url, uploading, uploadImage, downloadImage };
};

export default useImageStorage;

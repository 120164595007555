import { Card, Text } from '@ui-kitten/components';
import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import LoadingButton from '../../../components/LoadingButton';
import MiniProfilePreview from '../../../components/MiniProfilePreview';
import ProfilePreview from '../../../components/ProfilePreview';
import ScreenWrapper from '../../../components/ScreenWrapper';
import TextField from '../../../components/TextField';
import { SessionContext } from '../../../contexts/SessionContext';
import useFriendRequests from '../../../hooks/useFriendRequests';
import { Profile } from '../../../hooks/useProfile';
import { TabScreenProps } from '../../../navigation/utils/types';
import commonStyles from '../../../utils/commonStyles';
import { handlePostgrestError } from '../../../utils/handlePostgrestError';
import { supabase } from '../../../utils/supabase';

export default function FriendsScreen({
  navigation,
}: TabScreenProps<'Friends'>) {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<Profile[]>([]);
  const { receivedFriendRequests } = useFriendRequests();
  const session = useContext(SessionContext);

  const search = async () => {
    if (session) {
      try {
        setLoading(true);

        const { data, error, status } = await supabase
          .from('profiles')
          .select(
            `
            id,
            name,
            avatar_url,
            us_state,
            country_code,
            is_locked
            `,
          )
          .textSearch('name', query, {
            config: 'english',
            type: 'plain',
          })
          .neq('id', session.user.id)
          .limit(100);

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          setResults(data);
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to update your Feed!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ScreenWrapper>
      <Card style={commonStyles().formElement}>
        <View>
          <Text>Friend Requests</Text>
          {receivedFriendRequests &&
            receivedFriendRequests.map((request) => (
              <MiniProfilePreview
                key={request.from_profiles_id}
                id={request.from_profiles_id}
                onPress={() =>
                  navigation.navigate('UserProfile', {
                    profileId: request.from_profiles_id,
                  })
                }
                name={request.from.name}
                avatar_url={request.from.avatar_url}
              />
            ))}
          {receivedFriendRequests?.length === 0 && (
            <Text
              category="label"
              style={{ alignSelf: 'center', marginVertical: 4 }}
            >
              No friend requests yet!
            </Text>
          )}
        </View>
      </Card>
      <TextField
        placeholder="Friend name"
        value={query}
        onChangeText={setQuery}
      />
      <LoadingButton
        disabled={query.length < 2}
        isLoading={loading}
        onPress={search}
        text="Search for friends"
      />
      {results.map((profile) => (
        <ProfilePreview
          key={profile.id}
          onPress={() =>
            navigation.navigate('UserProfile', { profileId: profile.id })
          }
          {...profile}
        />
      ))}
    </ScreenWrapper>
  );
}

import { useNavigation } from '@react-navigation/native';
import { useState, useEffect, useContext, useCallback } from 'react';
import { SessionContext } from '../contexts/SessionContext';
import { handlePostgrestError } from '../utils/handlePostgrestError';
import { supabase } from '../utils/supabase';
import { RawPostType } from './usePosts';
import { Profile } from './useProfile';

const useUserProfile = (profileId: string) => {
  const [userProfile, setUserProfile] = useState<Profile | null>(null);
  const [profileLoading, setLoading] = useState(true);
  const [posts, setPosts] = useState<RawPostType[]>([]);

  const navigation = useNavigation();
  const session = useContext(SessionContext);

  const getUserProfile = useCallback(async () => {
    if (session) {
      try {
        setPosts([]);
        setLoading(true);

        const { data, error, status } = await supabase
          .from('profiles')
          .select(
            `
            id,
            name,
            avatar_url,
            us_state,
            country_code
            `,
          )
          .eq('id', profileId)
          .single();

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          setUserProfile(data);
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to get this Profile!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [navigation, profileId, session]);

  const getUserPosts = useCallback(async () => {
    if (session) {
      try {
        setLoading(true);

        const { data, error, status } = await supabase
          .from('posts')
          .select(
            `
            id,
            profile_id,
            content,
            created_at,
            updated_at,
            media_urls,
            comments (
              count
            ),
            profiles (
              name,
              avatar_url
            )
            `,
          )
          .eq('profile_id', profileId)
          .order('created_at', { ascending: false })
          .limit(100);

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          setPosts(
            data.map((post) => {
              const name = Array.isArray(post.profiles)
                ? (post.profiles?.[0].name as string)
                : (post.profiles?.name as string);
              const avatar_url = Array.isArray(post.profiles)
                ? (post.profiles?.[0].avatar_url as string)
                : (post.profiles?.avatar_url as string);
              const commentCount = Array.isArray(post.comments)
                ? (post.comments?.[0].count as string)
                : (post.comments?.count as string);
              return {
                ...post,
                commentCount,
                profiles: {
                  name,
                  avatar_url,
                },
              };
            }),
          );
        }
      } catch (error) {
        navigation.navigate('AlertModal', {
          title: 'Failed to update your Feed!',
          subtitle: handlePostgrestError(error).message,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [navigation, profileId, session]);

  useEffect(() => {
    void getUserProfile();
    void getUserPosts();
  }, [getUserPosts, getUserProfile]);

  return {
    userProfile,
    profileLoading,
    getUserProfile,
    posts,
  };
};

export default useUserProfile;

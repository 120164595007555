import {
  Autocomplete as AutocompleteComponent,
  AutocompleteItem,
} from '@ui-kitten/components';
import { useEffect, useState } from 'react';
import commonStyles from '../utils/commonStyles';
import countries, {
  countryCodeToCountry,
  CountryCode,
} from '../utils/countries';

export interface Country {
  name: string;
  code: string;
}

interface AutocompleteProps {
  onSelectItem: (item: Country) => void;
  defaultCountryCode?: CountryCode;
}

const filter = (item: Country, query: string) =>
  item.name.toLowerCase().includes(query.toLowerCase());

export default function CountryAutocomplete({
  onSelectItem,
  defaultCountryCode,
}: AutocompleteProps) {
  const [value, setValue] = useState<string>();
  const [filteredData, setFilteredData] = useState(countries);

  const onSelect = (index: number) => {
    setValue(filteredData[index].name);
    onSelectItem(filteredData[index]);
  };

  const onChangeText = (query: string) => {
    setValue(query);
    setFilteredData(countries.filter((item) => filter(item, query)));
  };

  useEffect(() => {
    if (defaultCountryCode) {
      const country = countryCodeToCountry(defaultCountryCode);
      if (country) {
        setValue(country.name);
        onSelectItem(country);
      }
    }
  }, [defaultCountryCode, onSelectItem]);

  const renderOption = (item: Country, index: number) => (
    <AutocompleteItem key={index} title={item.name} />
  );

  return (
    <AutocompleteComponent
      style={commonStyles().formElement}
      placeholder="Country"
      value={value}
      onSelect={onSelect}
      onChangeText={onChangeText}
    >
      {filteredData.map(renderOption)}
    </AutocompleteComponent>
  );
}
